import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const FieldCol1 = ({ fieldName, fieldPlace, name, value, handleInput, required }) => {
  return (
    <div className="self-stretch flex-col justify-start items-start gap-1.5 w-full  h-[300px] mb-3">
      <div className="self-stretch flex-col justify-start items-start gap-1.5">
        <label
          htmlFor={name}
          className="text-zinc-700 text-base font-semibold leading-tight"
        >
          {fieldName}
          {required && <span className="text-red-500">*</span>}
        </label>
        <div className="self-stretch bg-white rounded-lg shadow border border-gray-900  h-[300px]">
          <ReactQuill
            theme="snow"
            id={name}
            name={name}
            value={value || ""}
            placeholder={fieldPlace}
            onChange={(content) => {
              handleInput({
                target: {
                  name: name, // Ensure the correct `name` is passed
                  value: content, // Pass the updated content
                },
              });
            }}
            className="focus:outline-none border-none h-[257px] "
          />
        </div>
      </div>
    </div>
  );
};

export default FieldCol1;
