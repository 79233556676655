import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DashboardCard from './dashboardCard';
import SideBar from '../Parts/SideBar2';

import { delSemester, getSemesters, toggleActive } from '../../api/semesterApi';
import { selectLoggedInUser } from '../../redux/authSlice';
import { useSelector } from 'react-redux';

const SemesterDashboard = () => {
  const navigate = useNavigate();
  const [semesters, setSemesters] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const mainUser = useSelector(selectLoggedInUser);

  useEffect(() => {
    fetchSems();
  }, []);

  const fetchSems = async () => {
    try {
      const res = await getSemesters();
      setSemesters(res);
    } catch (error) {
      console.error(error);
    }
  }

  const handleEdit = (id) => navigate(`/admin/semester/${id}`)

  const handleDelete = async (id) => {
    try {
      await delSemester(id);
      fetchSems();
    } catch (error) {
      console.error(error);
    }
  }

  const handleActive = async (id, stat) => {
    try {
      const updated = await toggleActive(id, { isActive: !stat })
      setSemesters(semesters.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const filteredSemesters = semesters?.filter((semester) =>
    semester.semName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className="flex overflow-hidden">
      <SideBar />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {mainUser?.user?.name || "Name"}</h1>
        </header>
        <div className="flex flex-col items-center">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">Semester Dashboard</h2>
              <p className="text-gray-600 mb-4">Keep track of your semesters.</p>
            </div>

            <button
              className="h-[39px] mb-4 px-6 py-2 bg-orange-500 text-white rounded"
              onClick={() => navigate("/admin/semester")}
            >
              + Add New Semester
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2  px-2 py-2 -translate-y-[2px] -translate-x-[14.8px]  ml-[30px] ">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-yx-[38px] -translate-y-[3px]">
          {filteredSemesters && filteredSemesters.map((card, index) => (
            <DashboardCard
              key={index}
              name={card.semName}
              // code={card.code}
              date={card.createdAt?.substring(0, 10)}
              isActive={card.isActive}
              toggleActive={() => handleActive(card._id)}
              deletee={() => handleDelete(card._id)}
              edit={() => handleEdit(card._id)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SemesterDashboard
