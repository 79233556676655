import React, { useEffect, useRef, useState } from "react";
import arrow from "../../assets/chevron-down.svg";

const DropDown = ({ fieldName, array, handleInput, name, value, i, required }) => {
  const dropdownRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [filteredArray, setFilteredArray] = useState(array); // For filtering dropdown options

  // Only reset filteredArray when array changes
  useEffect(() => {
    setFilteredArray(array);
  }, [array]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle user selecting an option
  const handleSelect = (selectedValue) => {
    setInputValue(selectedValue);
    setShowOptions(false);
    handleInput({ target: { name: name, value: selectedValue } }, i ? i : null);
  };

  // Function to handle typing and filtering
  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);
    // console.log("userInput", userInput);

    // Use userInput directly here
    const filtered = array.filter(item =>
      item?.toLowerCase().includes(userInput?.toLowerCase())
    );
    // console.log("filtered", filtered);
    // console.log("filteredArray--->", filteredArray);
    setFilteredArray(filtered);


    // problem
    // handleInput({ target: { name: name, value: userInput } }, i ? i : null);
  };

  useEffect(() => {
    console.log("filteredArray updated:", filteredArray);
  }, [filteredArray]);

  return (
    <div ref={dropdownRef} className="flex flex-col w-full p-[2px]">
      <label htmlFor={fieldName} className="w-[250px] text-zinc-700 text-base font-semibold leading-tight ">
        {fieldName}{required && <span className="text-red-500 ">*</span>}
      </label>
      <div className="self-stretch bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 p-[2px]  inline-flex">
        <input
          type="text"
          className="w-full px-3.5 py-2.5 text-gray-700 focus:outline-none  ml-[2]"
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => setShowOptions(true)} // Show options when clicked
          placeholder={`Select ${fieldName}`}
        />
        <button
          type="button"
          className="px-3.5 py-2.5 hover:bg-violet-50"
          onClick={() => setShowOptions(!showOptions)}>
          <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
        </button>
      </div>

      {showOptions && (
        <>
          {filteredArray.length > 0 ? (
            <div className="self-stretch z-10 mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg max-h-40 overflow-y-auto">
              {filteredArray.map((field, index) => (
                <p key={index}
                  className="px-3.5 py-2.5 hover:bg-violet-50 cursor-pointer"
                  onClick={() => handleSelect(field)}>
                  {field}
                </p>
              ))}
            </div>
          ) : (
            <p className="px-3.5 py-2.5 text-gray-700">No options found</p>
          )}
        </>
      )}
    </div>
  );
};

export default DropDown;
