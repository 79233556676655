import React, { forwardRef, useEffect, useState, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfessorSpecializationData, selectProfessor } from "../../redux/professorSlice";
import SideBar from "../Parts/SideBar2";
import ProgressSteps4 from "./progressBar";
import FieldCol1 from "../Parts/FieldCol1";
import { toast } from 'react-toastify';

const Specialization = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const specialization = useSelector((state) => state.professor.professor.specializations);
  const [localData, setLocalData] = useState(specialization);
  const sectionRef = useRef(specialization);
  const professorData = useSelector(selectProfessor) || null

  useEffect(() => {
    if (professorData.specializations) {
      setLocalData(professorData.specializations);
    } else {
      setLocalData(specialization);
    }
  }, [specialization, professorData.specializations]);

  useEffect(() => {
    dispatch(updateProfessorSpecializationData(sectionRef.current));
  }, [dispatch]);

  const handleIntroTextChange = (e) => {
    const newIntroText = e.target.value;
    setLocalData({ ...localData, intro: newIntroText });
  };

  const handleSpecializationChange = (index, key, value) => {
    const updatedSpecializations = [...localData.specialization];
    updatedSpecializations[index] = { ...updatedSpecializations[index], [key]: value };
    setLocalData({ ...localData, specialization: updatedSpecializations });
  };

  const addNewSpecialization = () => {
    const newSpecialization = { name: '', desc: '' };
    const updatedSpecializations = [...localData.specialization, newSpecialization];
    setLocalData({ ...localData, specialization: updatedSpecializations });
  };

  const removeSpec = (ind) => {
    const newSpec = localData.specialization.filter((_, i) => i !== ind)
    setLocalData({ ...localData, specialization: newSpec });
  }

  const handleSubmit = () => {
    // Validate `specializations` object
    if (!localData) {
      toast.error("Specializations data is missing.");
      return false; // Return false for validation failure
    }

    if (!localData.intro) {
      toast.error("Specialization introduction is required.");
      return false; // Return false for validation failure
    }

    if (!Array.isArray(localData.specialization) || localData.specialization.length === 0) {
      toast.error("Please add at least one specialization.");
      return false; // Return false for validation failure
    }

    for (const spec of localData.specialization) {
      if (!spec.name || !spec.desc) {
        toast.error("Please fill in all the required fields for each specialization.");
        return false; // Return false for validation failure
      }
    }

    // If validation passes
    sectionRef.current = localData;

    // Dispatch the action to update the professor's specialization data
    dispatch(updateProfessorSpecializationData(localData));

    return true; // Return true for validation success
  };


  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="bg-purple-50 justify-start items-start inline-flex">
      <SideBar />
      <div className="grow ml-[15.5vw] shrink basis-0 self-stretch justify-start items-start inline-flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="3" />
                  </div>
                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Specializations
                        </p>
                      </div>
                    </div>
                  </div>

                  <FieldCol1
                    fieldName={"Intro text"}
                    fieldPlace={"I have experience XXX …."}
                    name="intro"
                    required
                    value={localData.intro}
                    handleInput={handleIntroTextChange}
                  />

                  <div className="bg-purple-100 self-stretch flex-col justify-start items-start gap-6 flex py-3.5 px-3.5">
                    {localData?.specialization.map((spec, index) => (
                      <div key={index} className="self-stretch flex-col justify-start items-start gap-6 flex">
                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex self-stretch">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor={`specialization${index}`}
                                className="text-zinc-700 text-base font-semibold leading-tight"
                              >
                                Specialization<span className="text-red-500">*</span>
                              </label>
                              <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                  <input
                                    id={`specialization${index}`}
                                    type="text"
                                    placeholder="'specialization'"
                                    className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                    value={spec.name}
                                    onChange={(e) => handleSpecializationChange(index, 'name', e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <FieldCol1
                          fieldName={"Specialization Description"}
                          fieldPlace={"I have specialization XXX …."}
                          name={`desc${index}`}
                          required
                          value={spec.desc}
                          handleInput={(e) => handleSpecializationChange(index, 'desc', e.target.value)}
                        />
                        <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={() => removeSpec(index)}>Remove</p>
                      </div>
                    ))}
                  </div>

                  <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={addNewSpecialization}>
                    + Add specialization
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Specialization;
