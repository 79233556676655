import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './dashboardCard';
import Sidebar2 from '../Parts/SideBar2';
import { delProfessor, getProfessors, toggleActive } from '../../api/professorApi';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../redux/authSlice';
import { toast } from 'react-toastify';

const ProfessorDashboard = () => {
  const [professors, setProfessors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const mainUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfessors();
  }, []);

  const fetchProfessors = async () => {
    try {
      const data = await getProfessors();
      setProfessors(data);
    } catch (error) {
      console.error('Error fetching professors:', error);
      toast.error('Error fetching professors. Please try again.');
    }
  };

  const handleEdit = async (id) => {
    try {
      navigate(`/admin/professor/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await delProfessor(id);
      toast.success("Professor deleted successfully.");
      setProfessors(professors.filter(p => p._id !== id)); // Optimistic update
    } catch (error) {
      console.error('Error deleting professor:', error);
      toast.error("Error deleting professor. Please try again.");
    }
  };

  const handleActive = async (id, stat) => {
    try {
      const updated = await toggleActive(id, { isActive: !stat });
      setProfessors(professors.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
      toast.error('Error updating professor status.');
    }
  };

  const filteredProfessors = professors?.filter((professor) => {
    const fullName = `${professor?.info?.name?.first || ''} ${professor?.info?.name?.last || ''}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  return (
    <div className="flex overflow-hidden">
      <Sidebar2 />
      <div className='ml-[15.5vw] p-4'>
        <header>
          <h1 className="text-2xl font-bold mb-4">
            Hi, {mainUser?.user?.name || "Name"} {/* Optional chaining for mainUser */}
          </h1>
        </header>

        <div className="flex flex-col items-center">
          <div className='flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center'>
            <div className=' flex flex-col'>
              <h2 className="text-xl font-semibold mb-1">Professor Dashboard</h2>
              <p className="text-gray-600 mb-4">Keep track of your professors.</p>
            </div>

            <button
              className="h-[39px] px-6 py-2 mb-4 bg-orange-500 text-white rounded"
              onClick={() => navigate('/admin/professor')}
            >
              + Add New Professor
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2 px-2 py-2 -translate-y-[2px] -translate-x-[14.8px] ml-[30px]">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search by name"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-y-[3px]">
          {filteredProfessors?.length > 0 ? (
            filteredProfessors.map((professor) => (
              <DashboardCard
                key={professor._id}
                name={`${professor?.info?.name?.first || ''} ${professor?.info?.name?.last || ''}`}
                date={professor.createdAt?.substring(0, 10) || 'N/A'}
                isActive={professor.isActive}
                toggleActive={() => handleActive(professor._id, professor.isActive)}
                deletee={() => handleDelete(professor._id)}
                edit={() => handleEdit(professor._id)}
              />
            ))
          ) : (
            <p>No professors found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfessorDashboard;
