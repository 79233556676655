import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
    // baseURL: `http://localhost:8080/`,
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  return user?.token;
};

export const authHeader = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

API.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;
