import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import SideBar from "../Parts/SideBar2";
import ProgressSteps4 from "./ProgressSteps4_students";
import FieldCol1 from "../Parts/FieldCol1";
import { useDispatch, useSelector } from "react-redux";
import { updateSkillsAndIntroText, selectStudent } from "../../redux/studentsSlice";
import { toast } from 'react-toastify';

const Skills = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const skillsData = useSelector((state) => state.students.skills);
  const studentData = useSelector(selectStudent) || null
  const [localData, setLocalData] = useState(skillsData);
  const sectionRef = useRef(skillsData);

  useEffect(() => {
    if (studentData.skills) {
      setLocalData(studentData.skills);
    } else {
      setLocalData(skillsData);
    }
  }, [skillsData, studentData.skills]);

  useEffect(() => {
    sectionRef.current = localData
  }, [localData]);

  useEffect(() => {
    dispatch(updateSkillsAndIntroText(sectionRef.current));
  }, [dispatch]);

  const handleIntroTextChange = (e) => {
    const { value } = e.target;
    const updated = { ...localData, intro: value };
    setLocalData(updated);
  };

  const handleSkillChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSkills = [...localData.skill];
    updatedSkills[index] = { ...updatedSkills[index], [name]: value };
    const updated = { ...localData, skill: updatedSkills };
    setLocalData(updated);
  };

  const handleAddSkill = () => {
    const updatedSkills = [...localData.skill, { name: '', desc: '' }];
    const updated = { ...localData, skill: updatedSkills };
    setLocalData(updated);
  };

  const removeSkill = (ind) => {
    const newSkills = localData.skill.filter((_, i) => i !== ind)
    const updated = { ...localData, skill: newSkills }
    setLocalData(updated);
  }

  const handleSubmit = () => {
    const skillsIntro = localData?.intro;

    if (!skillsIntro) {
      toast.error("Skills introduction is required.");
      return false; // Return false for validation failure
    }

    sectionRef.current = localData;
    dispatch(updateSkillsAndIntroText(localData));

    if (props.onSubmit) props.onSubmit();

    return true; // Return true for validation success
  };


  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="justify-start items-start inline-flex">
      <SideBar />

      <div className="grow ml-[15.5vw] shrink basis-0 self-stretch bg-purple-50 justify-start items-start flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">

              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="3" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Skills
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

                <FieldCol1
                  fieldName="Intro Text"
                  fieldPlace="I have Skills XYZ..."
                  name="intro"
                  required
                  value={localData.intro}
                  handleInput={handleIntroTextChange}
                />

                {localData.skill.map((skill, index) => (
                  <div className="self-stretch justify-start items-start gap-6 inline-flex" key={index}>
                    <div className="grow shrink basis-0 bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor={`skillName${index}`}
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            Skill
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id={`skillName${index}`}
                                type="text"
                                placeholder="Skill Name"
                                className="w-[75vw] grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                name="name"
                                value={skill.name}
                                onChange={(e) => handleSkillChange(index, e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <FieldCol1
                        fieldName="Skill Description"
                        fieldPlace="I have experience XYZ..."
                        name="desc"
                        value={skill.desc}
                        handleInput={(e) => handleSkillChange(index, e)}
                      />
                      <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={() => removeSkill(index)}>Remove Skill</p>
                    </div>
                  </div>
                ))}

                <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={handleAddSkill}>
                  + Add Skill
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Skills;
