import React, { useEffect, useRef, useState } from "react";
import SideBar from "../Parts/SideBar2";
import cloud from "../../assets/upload-cloud-02.svg";
import cross from "../../assets/cross.svg";
import JoditEditor from 'jodit-react';
import { useNavigate, useParams } from "react-router-dom";
import { createArticle, getArticleById, updateArticle } from "../../api/articleApi";
import { getProfessors } from "../../api/professorApi";
import RefDropDown from "../Parts/RefDropDown";
import { toast } from 'react-toastify';
import Imagekit from "../Parts/Imagekit";

const Article = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const editor = useRef(null);
  const [articleData, setArticleData] = useState({ title: "", creator: "", tags: [], description: "", fileUrl: '' });
  const [newTag, setNewTag] = useState("");
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState("");
  const [professors, setProfessors] = useState([]);

 

  const fetchProfessors = async () => {
    try {
      const res = await getProfessors();
      const data = res.map(p => p = { _id: p._id, creator: `${p.info.name.first} ${p.info.name.last}` });
      setProfessors(data);
      // console.log("professors: ", professors);
    } catch (error) {
      console.error(error);
    }
  }
  // console.log(articleData);
  const fetchArticleData = async () => {
    try {
      const res = await getArticleById(id);
      setArticleData(res);
      setUploadedFile(file?.name);
      // setContent(res.description)
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    fetchProfessors();
    if (id) fetchArticleData();
  }, [id]);

  const config = {
    width: '70vw',
    readonly: false,
    buttons: 'eraser,|,bold,italic,underline,strikethrough,|,fontsize,|,brush,lineHeight,|,image,table,link,|,indent,outdent,|,left,center,right,|,source,fullsize',
  }

  const handleInput = e => {
    setArticleData({ ...articleData, [e.target.id]: e.target.value });
  };

  const validateFileSize = (fileSize) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
    if (fileSize > MAX_FILE_SIZE) {
      toast.error("File size should not exceed 100 KB");
      return false;
    }
    return true;
  };

  const handleFileUpload = (res) => {
    if (validateFileSize(res.size)) {
      setUploadedFile(res.name); // Optionally set the uploaded file name
      setFile(res); // Store the file information
      setArticleData({ ...articleData, fileUrl: res.url });
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    if (!articleData.fileUrl) {
      toast.error("Please select an article.");
      return;
    }
  
    const payload = {
      title: articleData.title,
      creator: articleData.creator,
      tags: articleData.tags,
      description: articleData.description,
      fileUrl: articleData.fileUrl,
    };
  
    try {
      const res = id ? await updateArticle(id, payload) : await createArticle(payload);
      // console.log(res);
  
      setArticleData({
        title: "",
        creator: "",
        tags: [],
        description: "",
        fileUrl: "",
      });
      setFile(null);
      setUploadedFile("");
  
      nav('/dashboard/document');
    } catch (err) {
      console.error(err);      
    }
  };

  const deleteTag = tag => {
    setArticleData(prev => ({
      ...prev, tags: prev.tags.filter(t => t !== tag)
    }));
  }
  const addTag = e => {
    if (e.key === "Enter" && newTag.trim() !== "") {
      e.preventDefault();
      setArticleData(prev => ({
        ...prev, tags: [...prev.tags, newTag.trim()]
      }));
      setNewTag("");
    }
  }

  return (
    <div className="bg-purple-50">
      <div className="justify-start items-start inline-flex">
        <SideBar />
        <form onSubmit={handleSubmit} className="grow shrink basis-0 ml-[23vw] lg:ml-[15.5vw] self-stretch bg-purple-50 justify-start items-start flex">
          <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
            <div className="grow shrink basis-0 px-8 justify-center items-start flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch justify-start items-start gap-8 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                          <div className="text-zinc-700 text-4xl font-bold font-['Inter'] leading-tight">
                            Upload Article
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor="fileUrl"
                      className="w-[512px] flex-col justify-start items-start gap-4 inline-flex cursor-pointer"
                    >
                      <div className="self-stretch h-[126px] bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                        <Imagekit
                          id="fileUrl"
                          accept=".txt,.pdf,.docx"
                          onSuccess={(res) => {
                            console.log("res: ", res);
                            handleFileUpload(res);
                          }}
                        />
                        <div className="self-stretch h-full justify-center items-center gap-10 flex">
                          <div className="flex-col justify-start items-start gap-1 flex">
                            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-gray-900 text-sm font-semibold leading-tight">
                                  {uploadedFile || "Upload Article thumbnail"}
                                </p>
                              </div>
                            </div>
                            <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                              .txt, .pdf, .docx accepted (maximum 3MB)
                            </p>
                          </div>
                          {!uploadedFile && (
                            <div className="w-15 h-15 p-2.5 bg-gray-200 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                              <img
                                src={cloud}
                                alt="cloud"
                                className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                      <div className="h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="title"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              Title of Article
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="title"
                                  type="text"
                                  value={articleData.title}
                                  onChange={handleInput}
                                  required
                                  placeholder="Title"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="justify-start items-start gap-8 flex">
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">

                            <RefDropDown fieldName={"Creator"} array={professors} currentValue={articleData.creator} display={"creator"} theValue={"_id"} handleInput={handleInput} />

                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="tags"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              Tags
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              {articleData.tags?.map((tag, i) => (
                                <div key={i} className=" basis-0 h-6 justify-start items-center gap-2 flex ">
                                  <div className="px-3 bg-purple-100 rounded-[30px] justify-center items-center gap-1 flex">
                                    <div className="text-zinc-700 text-base font-normal font-['Inter'] leading-normal flex-nowrap max-w-[70px] min-w-[20px] max-h-6 overflow-hidden">
                                      {tag}
                                    </div>
                                    <img src={cross} alt="Remove tag" onClick={() => deleteTag(tag)} className="cursor-pointer" />
                                  </div>
                                </div>
                              ))}
                              <input
                                type="text"
                                value={newTag}
                                onChange={e => setNewTag(e.target.value)}
                                onKeyDown={addTag}
                                placeholder="+ Add tag"
                                className="flex-grow focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1.5 flex relative">
                      <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
                        <div className="self-stretch flex-col justify-start items-start gap-1.5 ">
                          <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="desc"
                              className="text-zinc-700 text-base font-semibold leading-tight"
                            >
                              Article Description
                            </label>
                            <div className="self-stretch grow shrink basis-0 bg-white rounded-lg shadow border border-gray-900 justify-start items-start p-[0.5px] gap-2 inline-flex flex-1">
                              <JoditEditor
                                ref={editor}
                                value={articleData.description}
                                tabIndex={1}
                                placeholder={"This Article contains XXX..."}
                                onBlur={newContent => setArticleData(prev => ({ ...prev, description: newContent }))}
                                onChange={newContent => { }}
                                config={config}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" self-stretch h-12 flex-col justify-start items-start gap-4 flex">
                      <button
                        type="submit"
                        className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
                      >
                        <p type="submit" className="text-white text-base font-semibold font-['Inter'] leading-normal">
                          Submit
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Article;
